<template>
  <div
    class="p-3 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
    @click="$emit('click')"
  >
    <feather-icon icon="PlusIcon" :svg-classes="classes" />
    <span v-if="title" class="ml-2 text-base text-primary">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    iconSize: {
      type: [Number, String],
      required: false,
      default: 4,
    },
  },
  computed: {
    classes() {
      return `h-${this.iconSize} w-${this.iconSize}`;
    },
  },
};
</script>
